import { Button, Link } from '@chakra-ui/react';

import { READ_MORE_FAQ } from '@/constants/analytics';
import { StrapiSectionDetail } from '@/customtypes/global';
import { trackEvent } from '@/dapp-sdk-v2';

import Section from '../Section';

interface KnowMoreProps {
  sectionDetails: StrapiSectionDetail;
}

const KnowMore = ({ sectionDetails }: KnowMoreProps) => {
  return (
    <Section
      title={sectionDetails.title ?? undefined}
      subTitle={sectionDetails.sub_title ?? undefined}
      id={sectionDetails.section_id ?? undefined}
    >
      {sectionDetails.cta_text && (
        <Link
          href={sectionDetails.cta_link ?? undefined}
          isExternal
          onClick={() => trackEvent(READ_MORE_FAQ)}
        >
          <Button w="238px" h="54px" mt="2rem" color="white">
            {sectionDetails.cta_text}
          </Button>
        </Link>
      )}
    </Section>
  );
};

export default KnowMore;
